<template>
  <div>
    <v-btn @click="createItem" small color="primary" class="mb-4">{{
      "action.create" | t
    }}</v-btn>
    <discount-config-item
      v-for="(config, index) in data"
      :key="index"
      :unit="unit"
      :index="index"
      v-model="data"
      @deleteItem="deleteItem"
    />
  </div>
</template>

<script>
import formItemMixin from "@/components/form/mixins/formItemMixin.js";
import discountConfigItem from "./discountConfigItem.vue";
export default {
  mixins: [formItemMixin],
  components: {
    discountConfigItem,
  },
  data: () => ({
    data: [],
  }),
  computed: {
    discountConfigs() {
      return this.formData.discount_config;
    },
    unit() {
      return this.formData.calculate_unit;
    },
  },
  watch: {
    discountConfigs: {
      deep: true,
      immediate: true,
      handler() {
        this.data = this.$eagleLodash.cloneDeep(this.discountConfigs) || [];
      },
    },
    data: {
      deep: true,
      handler() {
        if (this.$eagleLodash.isEqual(this.data, this.discountConfigs)) return;
        this.sync();
      },
    },
  },
  methods: {
    createItem() {
      this.data.push({
        min: 0,
        max: 0,
        price: 0,
      });
    },
    deleteItem(index) {
      this.data.splice(index, 1)
    },
  },
};
</script>