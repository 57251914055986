<template>
  <div class="mb-4">
    <v-row>
      <v-col cols="3">
        <v-text-field
          label="最小量"
          outlined
          dense
          type="number"
          clearable
          v-model="data.min"
        >
          <template #append-outer>
            <span>{{ unit }}</span>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="最大量"
          outlined
          dense
          type="number"
          clearable
          v-model="data.max"
        >
          <template #append-outer>
            <span>{{ unit }}</span>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="優惠單價"
          outlined
          dense
          type="number"
          clearable
          v-model="data.price"
        ></v-text-field>
      </v-col>

      <v-col cols="2">
        <v-btn
          @click="removeItem"
          color="error"
          small
          class="ml-auto"
          >{{ "action.delete" | t }}</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    unit: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
    },
  },
  data: () => ({
    data: null,
  }),
  computed: {
    discountConfigs() {
      return this.formData.discount_config;
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        const data = this.value[this.index];
        this.data = this.$eagleLodash.cloneDeep(data);
      },
    },
    data: {
      deep: true,
      handler() {
        const data = this.value[this.index];
        if (this.$eagleLodash.isEqual(this.data, data)) return;

        const value = this.$eagleLodash.cloneDeep(this.value);
        value[this.index] = this.$eagleLodash.cloneDeep(this.data);
        this.$emit("input", value);
      },
    },
  },
  methods: {
    removeItem() {
      this.$emit('deleteItem', this.index)
    },
  },
};
</script>